import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash.get"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/page-hero"
import Grid from "../components/grid"
import Breadcrumbs from "../components/breadcrumbs"
import Listing from "../components/listing"
import Pagination from "../components/pagination"

export const pageQuery = graphql`
  query MovieList($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    prismicFilms {
      data {
        name
        title
        description {
          richText
          text
        }
        image {
          alt
          copyright
          gatsbyImageData(layout: CONSTRAINED, width: 504, height: 280)
        }
      }
    }
    allPrismicFilm(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      pageInfo {
        currentPage
        pageCount
      }
      nodes {
        id
        uid
        type
        data {
          cover {
            alt
            copyright
            gatsbyImageData(layout: CONSTRAINED, width: 328, height: 476)
          }
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 328, height: 210)
          }
          title
          date
        }
      }
    }
  }
`

export default function Films({ location, data }) {
  const page = get(data, `prismicFilms.data`)
  const films = get(data, `allPrismicFilm.nodes`)
  const pageInfo = get(data, `allPrismicFilm.pageInfo`)

  return (
    <Layout location={location}>
      <SEO
        title={`${page.name} | Page ${page.currentPage}`}
        description={page.description.text}
      />

      <Grid>
        <Hero
          title={page.title}
          subtitle={page.description}
          image={page.image}
        />
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <span>{page.name}</span>
        </Breadcrumbs>
        <Listing nodes={films} />
        <Pagination path="/films" pageInfo={pageInfo} />
      </Grid>
    </Layout>
  )
}
